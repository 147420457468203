import { render, staticRenderFns } from "./ContactsCreate.vue?vue&type=template&id=ea479804"
import script from "./ContactsCreate.vue?vue&type=script&lang=js"
export * from "./ContactsCreate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports