<template>
  <div>
    <portal to="body-top">
      <customer-name title="- Add a Contact" />
    </portal>
    <div>
      <contact-form
        ref="contact-form"
        :is-create-form="true"
        :phone-options="phoneOptions"
      />
    </div>
  </div>
</template>
<script>
import ContactForm from '@/views/main/customers/view/contacts/components/ContactForm.vue'
import CustomerName from '../../../components/CustomerName.vue'
import config from '../contactsConfig'

export default {
  name: 'ContactsCreate',
  components: {
    ContactForm,
    CustomerName,
  },
  date() {
    return {
      datePattern: null,
      birthdayInputPlaceholder: null,
    }
  },
  async created() {
    this.$store.commit(`${this.MODULE_NAME}/SET_CONTACT_FORM`, {})
    this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_CUSTOMER_CONTACT_FORM_CLONE`, {})

    await this.$store.dispatch(`${this.MODULE_NAME_ORGANIZATION}/get`).then(res => {
      const { data } = res.data
      this.datePattern = data?.date_format.label.split('/').map(item => (item[0] === 'y' ? item[0].toUpperCase() : item[0].toLowerCase()))
      this.birthdayInputPlaceholder = data?.date_format.label
    }).catch(err => {
      this.error = err.response?.data
    })

    await this.$store.dispatch(`${this.MODULE_SETTINGS_DEFAULT_CUSTOMER_NAME}/getDefaultSettings`)
      .then(res => {
        const { data } = res.data

        const initialData = {
          is_active: data.contact_default_status,
          attachments: [],
          // phone_one_type: this.phoneOptions[0],
          //
          // phone_two_type: this.phoneOptions[1],
          //
          // phone_three_type: this.phoneOptions[2],
          //
          // phone_four_type: this.phoneOptions[3],

          // cell_no_object: { countryCode: 'US', isValid: false },
          // cell_no: '',
          // phone_no_object: { countryCode: 'US', isValid: false },
          // phone_no: '',
          // datePattern: this.datePattern,
          // birthdayInputPlaceholder: this.birthdayInputPlaceholder,
          birthday: '',
          // preferred_contact_method: null,
          contact_types: [],
          first_name: null,
        }
        this.$store.commit(`${this.MODULE_NAME}/SET_CONTACT_FORM`, initialData)
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_CUSTOMER_CONTACT_FORM_CLONE`, initialData)
        this.loadingInitialData = false
      })
    // this.$store.commit(`${this.MODULE_NAME}/RESET_CONTACT_FORM`)
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['contact-form']) {
      this.$refs['contact-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  setup() {
    const MODULE_NAME = 'contacts'
    const MODULE_SETTINGS_DEFAULT_CUSTOMER_NAME = 'settings-customer'
    const MODULE_NAME_CLONE = 'cloneData'
    const MODULE_NAME_ORGANIZATION = 'organization'

    const {
      createFields,
      phoneOptions,
    } = config()
    return {
      createFields,
      phoneOptions,
      MODULE_NAME,
      MODULE_NAME_CLONE,
      MODULE_SETTINGS_DEFAULT_CUSTOMER_NAME,
      MODULE_NAME_ORGANIZATION,
    }
  },
}
</script>
